<template>
  <div class="page-wrapper" :class="{ noscroll: overlayVisible }">
    <div class="home">
      <div class="home__header">
        <img class="home__logo" src="../../../assets/icons/logo-white.svg" alt="M Leuven logo" v-if="store.darkMode" />
        <img class="home__logo" src="../../../assets/icons/logo.svg" alt="M Leuven logo" v-else />
        <div @click="openSettings">
          <img src="../../../assets/icons/settings-white.svg" alt="" class="home__settings" v-if="store.darkMode" />
          <img src="../../../assets/icons/settings.svg" alt="" class="home__settings" v-else />
        </div>
      </div>
      <div class="home__item home__highlighted" v-if="featured.tours.length > 0">
        <h2 class="title">{{ featured.title }}</h2>
        <div class="home__highlighted-p" v-html="featured.intro"></div>
        <div class="home__carrousel">
          <RouterLink :to="{ name: 'tours', params: { id: tour.uuid } }" v-for="tour in featured.tours" :key="tour.uuid" class="home__carrousel-item">
            <div class="home__carrousel-img">
              <img :src="tour.headerImage" alt="" srcset="" />
            </div>
            <p class="home__carrousel-title">{{ tour.title }}</p>
          </RouterLink>
        </div>
      </div>

      <div class="home__item" v-if="today.exhibitions.length > 0">
        <h2 class="title">{{ today.title }}</h2>
        <div v-html="today.intro"></div>

        <div class="home__today">
          <RouterLink :to="{ name: 'expo', params: { id: item.uuid } }" class="home__today-item" v-for="item in today.exhibitions" :key="item.uuid">
            <div class="home__today-img">
              <img :src="item.headerImage.urlNormal" alt="" />
            </div>
            <div class="home__today-content">
              <div v-for="label in item.labels" :key="label" class="home__today-category">
                <p>{{ label.name }}</p>
              </div>
              <div>
                <p class="home__today-title">{{ item.title }}</p>
              </div>
            </div>
          </RouterLink>
        </div>
      </div>

      <div class="home__item" v-if="activities.activities.length > 0">
        <h2 class="title">{{ activities.title }}</h2>
        <div v-html="activities.intro"></div>

        <div class="home__extra">
          <a :href="`https://www.mleuven.be${activity.url}`" target="_blank" class="home__extra-item" v-for="activity in activities.activities" :key="activity.uuid">
            <div class="home__extra-img">
              <img :src="activity.image" alt="" />
            </div>
            <div class="home__extra-content">
              <p class="home__extra-title">{{ activity.title }}</p>
              <p class="home__extra-location">{{ activity.subtitle }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>

    <Actionbar :homeActive="true" @addNoScroll="(val) => (overlayVisible = val)" />
  </div>
</template>

<script src="./Home.js"></script>
